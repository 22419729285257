import {
  EActionType,
  EventCardRow,
  TAction,
  toLinkProps,
  buildEventsList,
} from '@front/shared/ds';
import { addJurisdictionSupport } from '@front/shared/utils';
import { Jurisdiction, Locale, Section } from '@shared/master-types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { useGetBlogEventsQuery } from '../../../features/blogEvents/getBlogEvents.query';

export type TEventCardRowConnectedProps = Extract<
  Required<Section>['rows'][number],
  {
    blockType: 'events-block';
  }
> & {
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
  handleEmptyContent?: () => void;
};

const EventCardRowConnected: React.FC<TEventCardRowConnectedProps> = ({
  perPage,
  emptyText,
  eventMenu,
  locales = [],
  jurisdictionList,
  jurisdictionItem,
  isUpcoming,
  eventsArchiveLinks,
  theme,
  handleEmptyContent,
}) => {
  const router = useRouter();
  const currentLocale = String(router.query.locale);
  const numberPerPage = Number(perPage);
  const currentDateISOString = useMemo(() => new Date().toISOString(), []);
  const [page, setPage] = useState(1);

  const eventsArchiveLinksWithAction = addJurisdictionSupport({
    jurisdictionList: jurisdictionList,
    localeList: locales,
    slug: jurisdictionItem?.slug,
    actionList: eventsArchiveLinks
      ? eventsArchiveLinks.map(
          (link): TAction => ({
            type: EActionType.LinkType,
            props: {
              children: link?.text,
              ...toLinkProps(String(router.query.locale), link),
            },
          }),
        )
      : [],
  });

  useEffect(() => {
    setPage(1);
  }, []);

  const eventsQuery: Parameters<typeof useGetBlogEventsQuery>[0] = {
    locale: currentLocale,
    draft: false,
    page: page,
    limit: numberPerPage,
    sort: isUpcoming ? 'startDate' : '-startDate',
    ...(isUpcoming === true
      ? {
          where: {
            endDate: { greater_than_equal: currentDateISOString },
          },
        }
      : {
          where: {
            endDate: { less_than: currentDateISOString },
          },
        }),
  };

  const { data: eventsDocs, isLoading } = useGetBlogEventsQuery(eventsQuery);

  const buildEventMenu = eventMenu?.map(menuItem => {
    return {
      links: addJurisdictionSupport({
        jurisdictionList: jurisdictionList,
        localeList: locales,
        slug: jurisdictionItem?.slug,
        actionList: [
          {
            type: EActionType.LinkType,
            props: {
              children: menuItem?.text,
              ...toLinkProps(currentLocale, menuItem),
            },
          },
        ],
      }),
    };
  });

  const handlePageChange = (e: { selected: number }): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(e.selected + 1);
  };

  const docsArray = eventsDocs?.docs || [];
  const totalPages = eventsDocs?.totalPages;
  const eventsList = buildEventsList(
    docsArray,
    currentLocale,
    locales,
    jurisdictionList,
    jurisdictionItem?.slug,
  );

  if (
    eventsList.length === 0 &&
    typeof handleEmptyContent !== 'undefined' &&
    !isLoading
  ) {
    handleEmptyContent();
  }

  return (
    <EventCardRow
      eventCardTheme={theme}
      isLoading={isLoading}
      eventsArchiveLinks={eventsArchiveLinksWithAction}
      eventMenu={buildEventMenu}
      eventsList={eventsList}
      emptyText={emptyText}
      currentPath={router.asPath}
      pagination={
        perPage !== '3' && Number(totalPages) >= 2 ? (
          <ReactPaginate
            pageCount={totalPages || 0}
            breakLabel='...'
            previousLabel={null}
            nextLabel={null}
            forcePage={page - 1}
            activeLinkClassName='bg-brand-500 hover:bg-brand-550 text-brand-50'
            containerClassName='flex justify-center gap-2 mt-16 text-sm font-medium leading-normal'
            pageLinkClassName='flex items-center justify-center h-8 w-10'
            pageClassName='bg-control-100 text-control-950 hover:bg-brand-550 hover:text-brand-50 overflow-hidden rounded-xl transition'
            breakLinkClassName={clsx(
              'flex h-8 w-10 items-center justify-center',
              page === 1 &&
                'rounded-xl bg-control-100 transition hover:bg-brand-550',
            )}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
        ) : (
          ''
        )
      }
    />
  );
};
export default EventCardRowConnected;
